import { Spinner } from "@fluentui/react"
import { errorToString, IApiKey, Org } from "@oneethos/shared"
import { useState } from "react"
import { FaTrash } from "react-icons/fa"
import { toast } from "react-toastify"
import api from '../api-client'
import { useApi } from "../hooks"
import { Link } from "./link"
import { NewApiKey } from "./new-api-key"

type ApiKeysProps = {
  org: Org
  onNewKey?: () => void
}

const LoadableLink = ({ onClick, children, title }) => {
  const [loading, setLoading] = useState(false)

  return loading ? <Spinner /> : <Link
    href="#"
    title={title}
    onClick={ev => {
      ev.preventDefault()
      setLoading(true)
      onClick().finally(() => setLoading(false))
    }}>{children}</Link>
}



export const ApiKeys = ({ org, onNewKey }: ApiKeysProps) => {
  const [newKey, setNewKey] = useState<IApiKey>(null)
  const [refetch, setRefetch] = useState(0)
  const { data: apiKeys, fetching, error } = useApi(`/orgs/${org._id}/api-keys`, [refetch])

  return <div>
    <h3>API Keys</h3>
    <p>
      API keys are used to authenticate your organization's integrations with the OneEthos API.
      You can create as many keys as you need, but each key should be kept secret and
      only used by the application for which it was created. Integrators
      may also create keys on behalf of your organization if OneEthos has enabled the integration
      for your organization.
    </p>
    {fetching ? <Spinner /> : null}
    {error ? <div className="alert alert-danger">{errorToString(error)}</div> : null}
    {apiKeys?.length === 0 && <div className="alert alert-secondary">No API keys have been created</div>}
    {apiKeys?.length > 0 ? <table className="table">
      <tbody>
        <tr>
          <th>Client Id</th>
          <th>Created</th>
          <th></th>
        </tr>
        {apiKeys.map(k => <tr key={k.clientId} className={k.archived ? 'table-secondary' : ''}>
          <td className={k.archived ? 'text-secondary' : ''}>
            <div>{k.clientId}</div>
            <div className="text-muted text-small">{k.integrationId}</div>
          </td>
          <td className={k.archived ? 'text-secondary' : ''}>{new Date(k.createdDate).toLocaleString()}</td>
          <td>
            {k.archived ? <span className="text-secondary">Archived</span> : <LoadableLink title="archive key"
              onClick={() => {
                if (window.confirm('Are you sure you want to archive this key? This will immediately disable API access for any applications using this key.')) {
                  return api.delete(`/api-keys/${k.clientId}`).then(() => {
                    onNewKey()
                  }).catch(ex => {
                    toast.error(errorToString(ex))
                  })
                } else {
                  return Promise.resolve()
                }
              }}><FaTrash /></LoadableLink>}
          </td>
        </tr>)}
      </tbody>
    </table> : null}
    {newKey ? <div className="alert alert-info">
      <p><span className="fw-bold">IMPORTANT:</span> This is the only time you will see the secret for these credentials.
        Please ensure it is stored securely. We cannot retrieve your secret if you lose it,
        you will have to generate a new Client Id + Secret.</p>
      <div className="form-group">
        <label>Client Id</label>
        <input className="form-control" type="text" value={newKey.clientId} readOnly />
      </div>
      <div className="form-group">
        <label>Client Secret</label>
        <input className="form-control" type="text" value={newKey.secret} readOnly />
      </div>

      <button
        onClick={() => {
          setNewKey(null)
          setRefetch(r => r + 1)
        }}
        className="btn btn-primary"
      >I have saved my secret credential</button>
    </div> : <NewApiKey onNewKey={setNewKey} org={org} />}
  </div>
}

export default ApiKeys