import { Document, FilterQuery, Query } from "mongoose"
import { SLoanApplication } from "./loan-application"
import { Dictionary } from "./types"
import { ITenant } from "./tenant"
import { Org } from "./org"

export type StatusUpdatePref = 'sms' | 'email' | 'sms-and-email' | 'none'
export type StatusEmailFreq = 'daily' | 'weekly' | 'monthly' | 'hourly' | 'never'
export type StatusUpdateType = 'all' | 'requires-installer-action'

export enum AccessLevel {
  PrimaryAdmin = 'primary_admin',
  Admin = 'admin',
  Rep = 'rep',
  Support = 'support',
  Integrator = 'integrator',
  SuperAdmin = 'super_admin',
  Banker = 'banker',
  Consumer = 'consumer'
}

export type KnownDevice = {
  uuid?: string 
  meta: string | Record<string, any> // userAgent OR browser fingerprint
  lastLogin?: Date
}

export type LoginRequest = {
  deviceId: string
  browser: Record<string, any>
  timestamp: string
  securityCode?: string
  attemptId?: string
}

export interface InstallerRegistrationPayload {
  _id: string
  id?: string // deprecated
  email: string
  emailVerified?: boolean
  name: string
  phone: string
  phoneVerified?: boolean
  installerID: string
  role: string
  access: AccessLevel
  active?: boolean
  statusUpdatePref?: StatusUpdatePref
  statusEmailFreq?: StatusEmailFreq
  statusUpdateType?: StatusUpdateType
  isAdmin: boolean
  isSupport: boolean
  isInstaller: boolean
  isConsumer: boolean
  isIntegrator: boolean
  org?: Org
  tenant?: string
  devices?: Dictionary<KnownDevice>
}

export interface Installer extends Document {
  active?: boolean
  installerID: string // the company this installer is associated with
  name: string
  email: string
  emailVerified?: boolean
  unverifiedPhone?: string
  unverifiedSMSCode?: string
  role: string // from airtable
  access: AccessLevel // portal defined
  approvedInstallers: string[]
  phone: string
  phoneVerified?: boolean
  statusUpdatePref?: StatusUpdatePref
  statusEmailFreq?: StatusEmailFreq
  statusUpdateType?: 'all' | 'requires-installer-action'
  devices?: Dictionary<KnownDevice>
  loginRequest?: LoginRequest
  mfaResetLog?: { timestamp: Date, notes: string, by: string }[]

  // consumer-specific fields
  abrigoCustomerId?: number
  abrigoCoborrowerCustomerId?: number

  airtableCreated: string
  airtableLastModified: string
  airtableTimestamp: string
  airtableId: string

  getRegistrationToken: () => string
  getRegistrationPayload: () => InstallerRegistrationPayload
  initializeLoginCode: (
    deviceId: string,
    browser: Record<string, any>,
    attemptId: string
  ) => Promise<string>
  getKnownLoginDates(): Date[]
  firstKnownLoginDate(): Date | undefined
  lastKnownLoginDate(): Date | undefined
  hasKnownDevices: () => boolean
  hasKnownDevice: (uuid: string) => boolean
  addKnownDevice: (device: KnownDevice) => void
  finishDeviceLogin(): Promise<Installer>
  isAdmin: () => boolean
  isIntegrator: () => boolean
  isInstallerAdmin: () => boolean
  isInstaller: () => boolean
  isSupport: () => boolean
  isConsumer: () => boolean
  isBanker: () => boolean
  isSuperAdmin: () => boolean
  shouldReceiveProjectUpdateEmail: (statusRequiresInstallerAction: boolean) => boolean
  shouldReceiveProjectUpdateSMS: (statusRequiresInstallerAction: boolean) => boolean
  sendLoginEmail: (
    deviceId: string,
    browser: Record<string, any>,
    tenant: ITenant,
    attemptId: string,
    route?: string,
    variant?: string
  ) => Promise<void>

  activeProjectsFilter(): FilterQuery<SLoanApplication>
  loanAppAccessFilter(integrationInstallerIds?: string[]): FilterQuery<SLoanApplication>
}
