import { Document, mongo } from 'mongoose'
import {
  Dictionary,
  Installer,
  InstallerStatus,
  StreetAddress,
  LoggedUpload,
  LoanDocument,
  SolarDoc,
  CitizenshipValue,
  ITenant,
  Org,
  Individual,
  SIndividual,
  ApplicantRace,
  IncomeDocument,
  Incentive
} from './index'
import { StatusData } from './status-data'
import { PreSubmitStatus, PreSubmitUtils } from './presubmit-status'

export type StatusTokenPayload = {
  id: string
  uploadType?: string
  role?: 'applicant' | 'installer'
}

export type BrowserAgreement = {
  browser: any
  timestamp: Date
  agrees: boolean
}

export type AirtableUserMeta = {
  id?: string
  email?: string
  name?: string
}

export type StepDocStatus = {
  requiredDocsCount: number
  approvedDocsCount: number
  docsNeedSupportActionCount: number
  docsNeedInstallerActionCount: number
  allDocsUploadedForStep: boolean
}

export type ProjectType = 'residential' | 'commercial' | 'non-profit'

export type InstallerDocMap = Dictionary<Dictionary<LoanDocument>>

export type PrequalResult = 'pass' | 'fail' | 'no-hit'

// not known if this is comprehensive list
export type Decision = "Approved-Pending Signed Contract" |
  "Approved" |
  "Booked (Solar + Roof)" |
  "Booked (Solar Only)" |
  "Canceled" |
  "Change Order Pending" |
  "Declined" |
  "Fully Funded" |
  "Loan Docs Requested" |
  "Partially Funded" |
  "Pending Phase 1 Funding" |
  "Pending Phase 2 Funding" |
  "Pending Roof Funding" |
  "Pending" |
  "Roof Funded" |
  "Withdrawn" |
  null

export type LoanApplication = {
  _id: string
  address: string
  airtableId: string
  appId?: number
  approvalDate?: Date
  applicationReceived: Date
  authorizedInstallers?: string[]
  avgMonthlyUtilityBill?: number
  customerName?: string
  businessName?: string
  projectType?: ProjectType
  principals?: Partial<Individual>[]
  firstName?: string
  lastName?: string
  birthDate?: string
  ssn?: string
  phone?: string
  driversLicenseNo: string
  driversLicenseIssuingState: string
  driversLicenseIssueDate: string
  driversLicenseExpirationDate: string
  hasMortgage?: 'yes' | 'no'
  incentive?: Incentive
  employerName: string
  occupation: string
  statedGrossAnnualIncome: string // "$100,000.00"

  id: string
  installersStatus: string
  email: string
  loanDurationMonths: number
  loanAmount: number
  solarCost?: number
  roofCost?: number
  storageCost?: number
  systemCost?: number
  otherCost?: number
  systemSizeKW?: number
  ppWForPV?: number
  interest: number
  installerEmail?: string[]
  installerID: string
  source?: {
    kind: 'web' | 'api'
    org: string
    key: string
  }
  branchId: string
  campaign: string
  decision?: string
  uploads?: LoggedUpload[]
  userAddress?: StreetAddress
  citizenship: CitizenshipValue
  race?: ApplicantRace
  statedHouseholdIncome?: string // "$100,000.00"
  coBorrowerAddress?: StreetAddress
  includeCoBorrower?: 'yes' | 'no'
  installationAddress?: StreetAddress
  aaNoticeGiven?: string
  nTPGiven?: string
  installedAtDifferentLocation?: 'yes' | 'no'
  incomeDocType?: 'paystubs' | 'W-2' | 'taxreturn'
  incomeVerificationDocuments?: SolarDoc[]
  useAutoDebit?: 'yes' | 'no'
  autoDebitAccountNo?: string
  autoDebitRoutingNo?: string
  autoDebitInstitution?: string
  agreesToTermsAndConditions?: BrowserAgreement
  agreesToProceedWithHardCreditInquiry?: BrowserAgreement
  stateInstalled?: string
  previousEmployment?: string
  yearsAtEmployer?: number

  coFirstName: string
  coLastName: string
  coSsn: string
  coPhone: string
  coEmail: string
  coBirthDate: string
  coDriversLicenseNo: string
  coDriversLicenseIssueDate: string
  coDriversLicenseIssuingState: string
  coDriversLicenseExpirationDate: string
  coCitizenship: CitizenshipValue
  coRace?: ApplicantRace
  coEmployerName: string
  coOccupation: string
  coYearsAtEmployer: string
  coPreviousEmployment: string
  coIncomeDocType?: 'paystubs' | 'W-2' | 'taxreturn'
  coIncomeVerificationDocuments?: SolarDoc[]
  coStatedGrossAnnualIncome: string // "$100,000.00"
  coStatedHouseholdIncome?: string // "$100,000.00"
  coHasMortgage?: 'yes' | 'no'
  lookup?: string

  abrigoProposedLoanId?: number
  abrigoCoborrowerLoanRoleId?: number
  abrigoLoanApplicationId?: number
  abrigoWorkflowId?: number
  error: string

  prequalResult?: PrequalResult
  prequalError?: string
  preSubmitStatus?: PreSubmitStatus
  submissionResponse?: any,
  notesVisibleToInstallers?: string

  // should be duplicates of branchId, installerID, and campaign respectively
  utm_medium: string
  utm_source: string
  utm_campaign: string

  rim: string
  installerDocs?: InstallerDocMap
  envelopes?: {
    envelopeId: string,
    timestamp: Date
  }[]
  lastStatusChange?: Date
  lastModified?: Date
  lastModifiedBy?: AirtableUserMeta
  createdDate?: Date
  updatedDate?: Date
  preapprovalLetter?: {
    doc: SolarDoc
    contentMD5: string
    agreement?: BrowserAgreement
  }

  // V1 new fields
  productId: string
  loanProposalId: string
  loanRequestId: string
  monthlyAverageEnergy: number
  batteryCost: number
  treeRemovalCost: number

  getStatusData: () => StatusData
  addOrUpdatePrincipal(p: Individual): SIndividual
  getReferralToken: () => string
  getStreet: () => string
  getDoc: (id: string) => LoanDocument | undefined
  getStepDocStatus: () => StepDocStatus
  isResidential: () => boolean
  lastUploadForStatus(status: string): LoggedUpload | undefined
  logUpload(
    uploadType: string,
    documents: LoanDocument[],
    uploadedBy?: string,
    notes?: string
  ): Promise<void>
  projectAddress: () => string
  estimatedPayment: () => string
  estimatedClosingCosts: () => number
  unsetSensitiveData: () => void
  renderableData: (t?: ITenant, org?: Org) => Record<string, string>
  hasMinPrequalInfo: () => boolean
  validateConsumerApplication_incomplete: () => string[]
} & Document

// servier loan application
export type SLoanApplication = LoanApplication & {
  consumerId: typeof mongo.ObjectId
  _id: typeof mongo.ObjectId
  creditReports: typeof mongo.ObjectId[]
  referralSubmittedBy: typeof mongo.ObjectId | string | Installer
  toMaskedObject(): Partial<SLoanApplication>
  updateConsumerAssignableFields(o: Partial<SLoanApplication>): void
  getPresubmitErrors(): string[]
  getLookupHash(): string
  addEnvelope(envelope: { envelopeId: string, timestamp: Date }): void
}

// client loan application
export type CLoanApplication = LoanApplication & {
  consumerId: string
  _id: string
}

export type SLoanAppField = keyof SLoanApplication
export type CLoanAppField = keyof CLoanApplication
export type LoanAppField = keyof LoanApplication

export const getIncomeDocErrors = (lapp: LoanApplication): string[] => {
  const {
    coIncomeVerificationDocuments: coDocs,
    incomeVerificationDocuments: docs,
    coIncomeDocType,
    incomeDocType,
    includeCoBorrower
  } = lapp

  const errors = []

  if (PreSubmitUtils.isAfter('Co-Borrower', lapp.preSubmitStatus)) {
    if (incomeDocType === 'paystubs' && docs?.length !== 2) {
      errors.push('Exactly two paystub documents required')
    } else if (['W-2', 'taxreturn'].includes(incomeDocType) && docs?.length !== 1) {
      errors.push('Exactly one W-2 or one tax return document required')
    } else if (!incomeDocType) {
      errors.push('Income verification documents required')
    }

    if (includeCoBorrower === 'yes') {
      if (coIncomeDocType === 'paystubs' && coDocs?.length !== 2) {
        errors.push('Exactly two paystub documents required for co-borrower')
      } else if (['W-2', 'taxreturn'].includes(coIncomeDocType) && coDocs?.length !== 1) {
        errors.push('Exactly one W-2 or one tax return document required for co-borrower')
      } else if (!coIncomeDocType) {
        errors.push('Co-borrower income verification documents required')
      }
    }
  }
  return errors
}

export const getIncomeDocErrorsMultiUpload = (lapp: LoanApplication, docsArray: IncomeDocument[], coDocsArray: IncomeDocument[]): string[] => {
  const {includeCoBorrower} = lapp

  const errors: string[] = []

  if (PreSubmitUtils.isAfter('Co-Borrower', lapp.preSubmitStatus)) {
    if (docsArray) {
      docsArray.forEach((docSet) => {
        if (docSet.docType === 'paystubs' && docSet.documents?.length !== 2) {
          errors.push(`Exactly two paystub documents required for primary borrower`)
        } else if (['W-2', 'taxreturn'].includes(docSet.docType) && docSet.documents?.length !== 1) {
          errors.push(`Exactly one W-2 or one tax return document required for primary borrower`)
        }
      })
    } 

    if (includeCoBorrower === 'yes' && coDocsArray) {
      coDocsArray.forEach((docSet) => {
        if (docSet.docType === 'paystubs' && docSet.documents?.length !== 2) {
          errors.push(`Exactly two paystub documents required for co-borrower`)
        } else if (['W-2', 'taxreturn'].includes(docSet.docType) && docSet.documents?.length !== 1) {
          errors.push(`Exactly one W-2 or one tax return document required for co-borrower`)
        } 
      })
    } 
  }
  return errors
}
