import { Document } from "mongoose"
import { PlaidApi } from "plaid"
import { UploadedDoc } from "./document-types"
import { ProjectType } from "./loan-application"
import { StreetAddress } from "./street-address"

export enum ConsumerApplicationIntegration {
  Abrigo = "Abrigo",
  DecisionPro = "DecisionPro",
}

export const IntegrationLabels = {
  [ConsumerApplicationIntegration.Abrigo]: 'Abrigo',
  [ConsumerApplicationIntegration.DecisionPro]: 'Airtable + DecisionPro',
}

export type TenantSiteType = 'consumer' | 'installer'

export interface IProduct {
  _id: string
  productType: string
  term: number
  interestOnlyPeriod: number
  apr: number
  autoPayAPR: number
  interestRateWithAutopay: number
  interestRateWithoutAutopay: number
  paymentFactorWithAutopay: number
  paymentFactorWithoutAutopay: number
  dealerFee: number
  maxPpw: number
  maxLoanAmount: number
  minLoanAmount: number
  disclosures: string
  approvedStates: string[]
  productDescription: string
  detailedProductDescriptionClientHtml: string
  detailedProductDescriptionInstallerHtml: string
}

export type ITenantClientData = {
  _id: string
  logo: UploadedDoc
  demoLocalFinancingPartnerLogo?: UploadedDoc
  demoLocalFinancingPartnerName?: string
  name: string
  site?: TenantSiteType
  interestRate: number
  interestRateCommercial: number
  rateEffectiveDate: string
  loanTermMonths: number
  loanTermMonthsCommercial: number
  loanIntroInterestOnlyMonths?: number
  plaidDisabled?: boolean
  products: IProduct[]
  trainingPageUrl?: string
  termsAndConditionsUrl?: string
  privacyPolicyUrl?: string
  esignAgreementUrl?: string
  advisory?: {
    message: string
    level: 'info' | 'warning' | 'danger'
  }
}

export type EquifaxPrequalConfig = {
  clientId?: string
  clientSecret?: string
  memberNo?: string
  securityCode?: string
  baseUrl?: string
  modelNo?: string
}

export type EquifaxDitConfig = {
  clientId?: string
  clientSecret?: string
  tenantId?: string
  applicationId?: string
  configId?: string
  entityId?: string
  baseUrl?: string
}

export type TenantEquifaxConfig = {
  prequal: EquifaxPrequalConfig,
  dit: EquifaxDitConfig
}

export type TenantPlaidConfig = {
  clientId?: string
  secret?: string
  env?: string
} & { toObject: () => TenantPlaidConfig }

export type ITenant = ITenantClientData & {
  installerHostname: string
  consumerHostname: string
  referralUrl?: string
  referralUrlCommercial?: string
  dbName: string
  installerSupportEmail: string
  bankEmail: string
  bankPhone: string
  bankNotifyAppSubmittedEmail?: string
  zendeskToEmail: string
  primaryAddress: StreetAddress
  docUploadFromEmail: string
  docUploadWebhookUrl: string
  docUploadWebhookSecret: string
  enableResidential: boolean
  enableCommercial: boolean
  enableNonProfit: boolean
  consumerApplicationIntegration: ConsumerApplicationIntegration
  abrigoClientId?: string
  abrigoClientSecret?: string
  abrigoEnv?: string
  abrigoProductId?: string
  abrigoSolarWorkflowTemplateId?: string
  abrigoMarkStepCompleteOnDocUpload?: boolean
  decisionProSubmitUrl?: string
  decisionProProductName?: string
  airtableAccessToken?: string
  achOdfiRoutingNo: string
  achOdfiName: string
  achOdfiAccountNo: string
  achOdfiAccountType: string
  letterheadLogo?: UploadedDoc
  marketingDomain?: string
  products: IProduct[]
  equifax?: TenantEquifaxConfig
  plaid?: TenantPlaidConfig
  getOrigin: (s: string) => string
  getConsumerOrigin: () => string
  getInstallerOrigin: () => string
  getPlaidClient: () => PlaidApi
  getReferralUrl: (projectType?: ProjectType) => string
  clientData: () => ITenantClientData
  toMaskedObject: () => ITenant
  defaultFileStorageContainerName: () => string
  addProductFields: () => IProduct
} & Document

export const initialTenantProduct =  {
  productType: '',
  term: 0,
  interestOnlyPeriod: 0,
  apr: 0,
  autoPayAPR: 0,
  interestRateWithAutopay: 0,
  interestRateWithoutAutopay: 0,
  paymentFactorWithAutopay: 0,
  paymentFactorWithoutAutopay: 0,
  dealerFee: 0,
  maxPpw: 0,
  maxLoanAmount: 0,
  minLoanAmount: 0,
  disclosures: '',
  approvedStates: [],
  productDescription: '',
  detailedProductDescriptionClientHtml: '',
  detailedProductDescriptionInstallerHtml: '',
}