import { Spinner } from "@fluentui/react"
import { AccessLevel, errorToString, Org } from "@oneethos/shared"
import { useState } from "react"
import { toast } from "react-toastify"
import api from "../api-client"
import { useAppState } from "../hooks"


export const NewApiKey = ({ onNewKey, org }) => {
  const [saving, setSaving] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showForm, setShowForm] = useState(false)
  const [integrations, setIntegrations] = useState([])
  const [integrationId, setIntegrationId] = useState('')
  const { registration: { installer } } = useAppState()

  return showForm ? <div>
    <h4>Create API Key</h4>
    {loading ? <Spinner /> : <div className="form-group">
      <p>
        Select an Integration to use with this API Key. If selected, API activity
        from this key will be visible to integration administrators.
      </p>
      <select className="form-select"
        value={integrationId}
        onChange={ev => setIntegrationId(ev.target.value)}
        style={{ maxWidth: '400px' }}
      >
        <option value="">Select Access...</option>
        {(
          installer.installerID === org.installerID
        ) ? <option value="internal">Internal Use</option> : null}
        {integrations.map(({ integrationId }) => (
          <option key={integrationId} value={integrationId}>Integration: {integrationId}</option>
        ))}
      </select>
    </div>}
    <div className="buttons">
      <button className="btn btn-primary"
        disabled={!integrationId}
        onClick={() => {
          setSaving(true)
          api.post('/api-keys', { orgId: org._id, integrationId }).then(key => {
            onNewKey(key)
            setSaving(false)
          }).catch(ex => {
            toast.error(errorToString(ex))
            setSaving(false)
          })
        }}>Create API Key</button>
      <button className="btn btn-secondary" onClick={() => setShowForm(false)}>Cancel</button>
    </div>
  </div> : <button
    className="btn btn-primary"
    disabled={saving}
    onClick={() => {
      setLoading(true)
      api.get('/orgs/integrations').then(data => {
        setIntegrations(data)
        setShowForm(true)
      }).catch(ex => toast.error(errorToString(ex)))
        .finally(() => setLoading(false))
    }}
  >{saving ? <Spinner /> : 'Configure New API Key...'}</button>
}

