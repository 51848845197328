import { DataSync, InstallerRegistrationReport, InstallerStatusEditor, Section, TenantSettings } from "../components"

export const BankerDashboardPage = () => {
  return <div className="page">
    <Section>
      <TenantSettings />
    </Section>
    <Section>
      <InstallerStatusEditor />
    </Section>
    <Section>
      <InstallerRegistrationReport />
    </Section>
    {process.env.REACT_APP_NODE_ENV === 'test' ? <Section>
      <h4>Danger Zone</h4>
      <p>Please make sure you know what you're doing when pushing these buttons :)</p>
      <DataSync />
    </Section> : null}
  </div>
}

export default BankerDashboardPage