import { Spinner } from "@fluentui/react"
import { AccessLevel, errorToString, Org } from "@oneethos/shared"
import React, { useState, useEffect } from "react"
import { toast } from "react-toastify"
import api from '../api-client'
import { useApi, useAppState } from "../hooks"
import { Checkbox, MultiSelect, PasswordInput, RoutingNumberInput, STATE_CODES, TextInput } from "./forms"
import Section from "./section"
import { ApiKeys } from "./api-keys"
import { TabNav } from "./tab-nav"

type OrgAdminProps = {
  installerID: string
}

export const EnabledIntegrations = ({ onChange, enabledIntegrations }) => {
  const { data: integrations, fetching, error } = useApi(`/orgs/integrations`, [])

  if (fetching) {
    return <Spinner />
  }

  return <div>
    <h5>Enabled Integrations</h5>
    {error ? <div className="alert alert-danger">{error}</div> : null}
    <p>
      Enabling an integration will make this Organization
      visible to Integration Admins for the Integrator
    </p>
    {integrations?.length ? <div>
      <MultiSelect
        options={integrations.reduce((acc, i) => {
          acc[i.integrationId] = i.integrationId
          return acc
        }, {})}
        values={enabledIntegrations || []}
        onChange={onChange}
      />
    </div> : <div className="well">No integrations enabled</div>}
  </div>
}

export const OrgAdmin = ({ installerID }: OrgAdminProps) => {
  const [err, setErr] = useState('')
  const [org, setOrg] = useState<Partial<Org>>(null)
  const [saving, setSaving] = useState(false)
  const [refetch, setRefetch] = useState(0)

  const { registration: { installer } } = useAppState()
  const { data, fetching, error } = useApi(`/orgs/${installerID}`, [refetch])

  const showPaymentInfo = installer.isAdmin || installer.isSupport
  const TabOptions = ['Basic Information', 'Integration']
  if (showPaymentInfo) {
    TabOptions.push('Payment')
  }

  type TabOption = typeof TabOptions[number]
  const [nav, setNav] = useState<TabOption>('Basic Information')

  useEffect(() => {
    if (data) {
      if (!data.operatingStates?.length) {
        data.operatingStates = ['FL']
      }

      setOrg(data)
    }
  }, [data])

  if (fetching) {
    return <Spinner />
  }

  if (error) {
    return <Section>
      <div className="alert alert-danger">{errorToString(error)}</div>
    </Section>
  }

  return <div className="org-admin">
    {installer.isSupport ? <Section>
      <div className="section-title">
        <h3 className="mb-0">Installer Org Settings</h3>
      </div>

      <TabNav
        tabs={TabOptions}
        current={nav}
        onNav={setNav}
      />

      {nav === 'Integration' ? <>
        <div className="form-group">
          <Checkbox
            label="API Enabled"
            value={org?.apiEnabled}
            onChange={ev => setOrg({ ...org, apiEnabled: ev.target.checked })}
          />
        </div>
        <div className="form-group">
          <div className="alert alert-secondary mb-1">
            <div>Setting the Org as an Integration partner will:</div>
            <ul className="mb-0">
              <li>
                Enable the Org to to display as an integration
                in org settings (for support users only)
              </li>
              <li>Enable support users to associate other orgs with this org</li>
              <li>Enable <b>Integration Admin</b> roles for this org</li>
            </ul>
          </div>
          <Checkbox
            label="This Organization is an Integration Partner"
            value={org.isIntegrator}
            onChange={ev => setOrg({ ...org, isIntegrator: ev.target.checked })}
          />
        </div>
        <div className="form-group">
          <EnabledIntegrations
            enabledIntegrations={org.enabledIntegrations}
            onChange={enabledIntegrations => setOrg({
              ...org,
              enabledIntegrations
            })}
          />
        </div>
      </> : null}
      {nav === 'Basic Information' ? <>
        <div className="form-group">
          <label>Operating States</label>
          <MultiSelect
            options={STATE_CODES}
            values={org?.operatingStates || []}
            onChange={states => setOrg({ ...org, operatingStates: states })}
            wrapStyle={{ maxHeight: '26em', overflowY: 'auto' }}
          />
        </div>
      </> : null}
      {showPaymentInfo && nav === 'Payment' ? <>
        {/* {installer.access === 'primary_admin'
        ? <PlaidAuthSelect 
                  onAuthSelect={ach => console.log(ach)}
          onManual={() => console.log('manual')}
          prompt="Add Payment Information"
         />
        : <div>Contact your primary administrator to verify payment information</div>} */}
        <h4 className="mt-3">
          ACH Payment Information
        </h4>
        <div className="form-group">
          <label>Institution Name</label>
          <TextInput
            value={org?.achInstitutionName || ''}
            onChange={v => setOrg({ ...org, achInstitutionName: v })}
          />
        </div>
        <div className="form-group">
          <label>Routing Number</label>
          <RoutingNumberInput
            value={org?.achRoutingNo || ''}
            onChange={v => setOrg({ ...org, achRoutingNo: v })}
          />
        </div>
        <div className="form-group">
          <label>Account Number</label>
          <PasswordInput
            value={org?.achAccountNo || ''}
            onChange={v => setOrg({ ...org, achAccountNo: v })}
          />
        </div>
        <div className="form-group">
          <label>Account Type</label>
          <select value={org?.achAccountType}
            className="form-select"
            onChange={ev => setOrg({ ...org, achAccountType: ev.target.value })}>
            <option></option>
            <option value="checking">Checking</option>
            <option value="savings">Savings</option>
          </select>
        </div>
      </> : null}
      <div className="buttons">
        <button className="btn btn-primary"
          disabled={saving}
          onClick={ev => {
            setSaving(true)
            api.patch(`/orgs/${installerID}`, org)
              .then(_org => { toast.success('Org Settings Updated'); setOrg(_org) })
              .catch(ex => { setErr(errorToString(ex)) })
              .finally(() => { setSaving(false) })
          }}
        >{saving ? <Spinner /> : 'Save Org Settings'}</button>
      </div>
    </Section> : null}
    {installer.isAdmin && org.apiEnabled ? <Section>
      <ApiKeys org={org as Org} />
    </Section> : null}
  </div>
}

export default OrgAdmin