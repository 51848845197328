import fileDownload from "../lib/file-download"
import api from '../api-client'
import { toast } from "react-toastify"
import { errorToString } from "@oneethos/shared"

export const InstallerRegistrationReport = () => (
  <div>
    <h3>Installer Registration Report</h3>
    <button type="button" className="btn btn-primary"
      onClick={() => {
        api.authenticatedFetch('/installers/registration-report', {
          responseType: 'blob'
        }).then(async res => {
          const disposition = res.headers.get('Content-Disposition')
          const filename = disposition.split('filename=')[1].replace(/"/g, '')
          const blob = await res.blob()
          fileDownload(blob, filename)
        }).catch(ex => toast.error(errorToString(ex)))
      }}
    >
      Installer Registrations Report
    </button>
  </div>
)

export default InstallerRegistrationReport