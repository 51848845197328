import React, { useReducer, FC, useEffect, useState, useContext } from 'react'
import {
  Navigate,
  Route,
  RouterProvider,
  Routes,
  createBrowserRouter,
  createRoutesFromElements,
  redirect,
  useNavigate
} from 'react-router-dom'
import { ThemeProvider, Spinner, SpinnerSize } from '@fluentui/react'
import * as Pages from './pages'
import api from './api-client'
import { useAppState, useLocalStorage } from './hooks'
import { AuthError, HeaderLogo, MiniSection, Sidebar, TopNav } from './components'
import { StorageKey } from './lib'
import { FaTriangleExclamation } from 'react-icons/fa6'
import useScreenSize from './hooks/use-screen-size'

const Advisory = ({ advisory }) => {
  if (!advisory) return null

  const { level, message } = advisory

  return <MiniSection type={level}>
    <div className="fs-4 mb-2 fw-semibold"><FaTriangleExclamation size={20} /> System Advisory</div>
    {message}
  </MiniSection>
}

const RouterWrap = () => {
  const {
    registration: { installer, loading, authError },
    tenant
  } = useAppState()
  const [impersonate, , clearImpersonate] = useLocalStorage(StorageKey.ImpersonateUserId, undefined)
  const { width } = useScreenSize()
  const [sidebarOpen, setSidebarOpen] = useState(width > 768)

  if (loading || tenant.loading) {
    return <div style={{ padding: '10rem' }}>
      <Spinner size={SpinnerSize.large} />
    </div>
  }

  return <div className={[
    "installer-page",
    sidebarOpen ? 'sb-open' : '',
    installer ? 'logged-in' : ''
  ].join(' ')}>
    {installer ? <Sidebar onToggle={() => setSidebarOpen(!sidebarOpen)} isOpen={sidebarOpen} /> : null}
    <div className="page-content">
      <TopNav />
      {(installer && impersonate) ? <div>
        <div className="impersonate-warning">
          <MiniSection type='danger'>
            {/* 
            Note that emails generated on behalf of this user will appear to come from the impersonating user, which 
            is possibly a security risk if the banker or super admin is acting in good faith.... although we can't actually
            send from the user, so maybe it's a non-issue?
          */}
            <p>You are impersonating <b>{installer.email}</b> ({installer.access}) with <b>{installer.installerID}</b></p>
            <div className="buttons">
              <button type="button" className="btn btn-danger"
                onClick={() => {
                  clearImpersonate()
                  window.location.reload()
                }}
              >Exit</button>
            </div>
          </MiniSection>
        </div>
        {/* push content down the page to acommodate fixed position warning */}
        <div style={{ height: '8em' }}></div>
      </div> : null}
      {authError ? <AuthError authError={authError} /> : null}

      <Advisory advisory={tenant?.config?.advisory} />
      <Routes>
        <Route path="/apply/:id" element={<Pages.ConsumerApplicationPage />} />
        <Route path="/apply" element={<Pages.ConsumerApplicationPage />} />
        <Route path="/login" element={<Pages.LoginPage />} />
        <Route path="/verify-email" element={<Pages.VerifyEmailPage />} loader={() => {
          if (localStorage.getItem(StorageKey.InstallerToken)) {
            throw redirect('/installer-pos')
          }

          return null
        }} />

        {/* legacy login workflow */}
        <Route path="/verify-login-token" element={<Pages.VerifyEmailPage />} />
        {!installer ? (<>
          <Route path="/profile" element={<Pages.InstallerProfilePage />} />
          <Route path="*" element={<Navigate to="/login" />} />
        </>) : <>
          {installer.isConsumer ? (
            <>
              <Route path="/applications" element={<Pages.ApplicationsPage />} />
              <Route path="/profile" element={<Pages.InstallerProfilePage />} />
              <Route path="*" element={<Navigate to="/applications" />} />
            </>
          ) : (
            <>
              <Route path="/profile" element={<Pages.InstallerProfilePage />} />
              <Route path="/calculator" element={<Pages.CalculatorPage />} />
              {installer.isIntegrator ? <Route path="/installers" element={<Pages.InstallerOrgsPage />} /> : null}
              {installer.isSupport ? <>
                <Route path="/dashboard" element={<Pages.BankerDashboardPage />} />
                <Route path="/prequal-reports" element={<Pages.CreditReportsPage />} />
              </> : null}

              <Route path="/projects/:installerID" element={<Pages.InstallerProjectsPage />} />
              <Route path="/project/:id" element={<Pages.ProjectPage />} />
              <Route path="/projects" element={<Pages.InstallerProjectsPage />} />
              <Route path="/training" element={<Pages.TrainingPage />} />
              <Route path="/installer-admin/:installerID" element={<Pages.InstallerAdminPage />} />
              <Route path="/installer-admin" element={<Pages.InstallerAdminPage />} />

              {/* legacy route(s) */}
              <Route path="/installer-pos" element={<Pages.CalculatorPage />} />
              <Route path="/doc-upload" element={<Pages.DocUploadPage />} />

              <Route path="*" element={<Navigate to="/installer-pos" />} />
            </>
          )}
        </>}
      </Routes>
      <div className="oe-footer">
        <div className="powered-by">
          <img src="/images/pb-oneethos-black.png" height="50" alt="oneethos-logo" />
        </div>
      </div>
    </div>
  </div >
}

// not a huge fan of this approach, but otherwise struggled to redirect
// to installer-pos if token already exists; otherwise verify-email page
// is buggy
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="*" element={<RouterWrap />} />
  )
)

export default router